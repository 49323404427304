import Datepicker from 'vanillajs-datepicker/Datepicker';
import 'vanillajs-datepicker/css/datepicker-foundation.css';

const today = new Date();
today.setHours(0, 0, 0, 0);

const elemIn = document.querySelector('input[name="checkIn"]');
const datepickerCheckin = new Datepicker(elemIn, {
    format: 'dd-mm-yyyy',
    minDate: today,
}); 

const elemOut = document.querySelector('input[name="checkOut"]');
const datepickerCheckout = new Datepicker(elemOut, {
    format: 'dd-mm-yyyy',
    minDate: today,
});

elemIn.addEventListener('changeDate', (e) => {
    const checkinDate = e.detail.date;
    const nextDay = new Date(checkinDate);
    nextDay.setDate(nextDay.getDate() + 2);
  
    datepickerCheckout.setOptions({
      minDate: nextDay,
    });
  
    // Set the value of the checkout date to 1 day after the checkin date
    elemOut.value = nextDay.toISOString().split('T')[0];
    datepickerCheckout.update(); // Update the datepicker to reflect the new date
});
